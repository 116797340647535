import React, { useState, useEffect } from "react";
import axios from 'axios';

import "./tournament-heat.scss";

import { HEAT_DATA } from "../../mockData/heat-data";

const TournamentHeat = () => {

    const createHeats = () => {
        let heats = []
        for (let i = 0; i < Object.keys(HEAT_DATA.heats).length; i++) {
            heats.push(
                <div className="heat-col">
                    <div className="heat-title">Game {i + 1}</div>
                    <div className="heat-item">
                        <div>Team</div><div>Score</div>
                    </div>
                    {HEAT_DATA.heats[Object.keys(HEAT_DATA.heats)[i]].map((entrant, index) => (
                        <div key={index} className="heat-item" id={entrant.name}>
                            <div>{entrant.name}</div><div>{entrant.score}</div>
                        </div>
                    ))}
                </div>
            )
        }
        return heats;
    }

    return (
        <div className="tournament-heat-container">
            {createHeats()}
        </div>
    )

};

export default TournamentHeat;